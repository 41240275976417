
import {
  alertController,
  IonButton, IonButtons, IonCol,
  IonContent, IonGrid,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel, IonList,
  IonPage, IonRow, IonSelect, IonSelectOption,
  IonTitle,
  IonToolbar, loadingController,
  modalController, onIonViewWillEnter, onIonViewWillLeave
} from "@ionic/vue";
import {computed, defineComponent, ref, watch} from "vue";
import {
  CreateMaterial,
  emptyComponent,
  emptyCreateProduct,
  newComponent
} from "@/domain/command/material/material.command";
import {useMaterialApi} from "@/domain/service/api/material.api";
import {useStore} from "vuex";
import {Component, Material} from "@/domain/model/material/material.model";
import Autocomplete from "@/app/component/autocomplete/Autocomplete.vue";
import ProductCreateItemComponent from "@/app/view/production/create/ProductCreateItemComponent.vue";
import {uuid4} from "@capacitor/core/dist/esm/util";
import _ from "lodash";
import router from "@/app/router";
import {useViewStateApi} from "@/domain/service/api/viewState.api";

export default defineComponent({
  name: "ProductCreatePage",
  components: {
    ProductCreateItemComponent,
    IonGrid,
    IonRow,
    IonCol,
    IonPage,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonItem,
    IonInput,
    IonButton,
  },
  setup() {
    const store = useStore();
    const {createMaterial} = useMaterialApi();
    const placeholder = ref<string>('Selecciona componente...');
    const referenceKey = ref<string>('');
    const isSubmitting = ref(false);
    const command = ref<CreateMaterial>(store.getters.getKitBeforeCreating);
    const onEnterCount = ref(0);

    const componentsFromSelect = ref([]);
    const materials = computed(() => store.getters.allPartsMaterials.map((materialNode: Material) => {
      return materialNode.ref;
    }));

    const filterValidItems = (items: Array<any>) => {
      return _.reject(items, (item) => item.reference === '');
    };

    const validValuesLength = (): number => {
      return _.filter(command.value.components, (component: Component) => component.ref !== '').length;
    }
    const isAtLeastOneItem = computed<boolean>(() => {
      return validValuesLength() > 0;
    });

    const onRefExceptionAlert = async () => {
      const alert = await alertController
          .create({
            cssClass: 'my-custom-class',
            header: 'Alerta',
            subHeader: 'Referencia repetida',
            message: 'Esa referencia ya existe!',
            buttons: [
              {
                text: 'Aceptar',
                role: 'success',
                cssClass: 'danger',
                handler: () => {
                  console.log('submit abort');
                },
              },
            ],
          });

      await alert.present();

      const {role} = await alert.onDidDismiss();
    }


    const submit = async () => {
      if (isSubmitting.value)
        return;

      const loading = await loadingController
          .create({
            message: 'Finalizando Ordenes',
            duration: 5000,
          });

      if (store.getters.materialByRef(command.value.ref.toUpperCase()) || store.getters.materialByRef(command.value.ref.toLowerCase())) {
        await onRefExceptionAlert();
        return;
      }
      isSubmitting.value = true;
      await loading.present();

      const newCommand: Material = _.cloneDeep(command.value);

      await createMaterial(newCommand);
      await useViewStateApi().resetKitState();

      isSubmitting.value = false;

      await loading.dismiss();
      await router.push({name: `StockRecord`});
    };

    const addEmptyItem = () => {
      const items: Component = {
        ref: '', amount: 0
      };
      command.value.components.push(items);
    };
    const onNextItem = () => {
      addEmptyItem();
    }

    const onDeleteItem = (index: number) => {
      if (index > -1)
        command.value.components.splice(index, 1);
    }

    onIonViewWillEnter(() => {
      onEnterCount.value = onEnterCount.value + 1000;
      command.value = store.getters.getKitBeforeCreating;
    });

    return {
      onEnterCount,
      addEmptyItem,
      onDeleteItem,
      onNextItem,
      isAtLeastOneItem,
      referenceKey,
      placeholder,
      componentsFromSelect,
      command,
      isSubmitting,
      submit,
      materials,
    };
  }
})
