
import {defineComponent, ref} from "vue";
import {IonCol, IonGrid, IonIcon, IonInput, IonItem, IonRow} from "@ionic/vue";
import {Material} from "@/domain/model/material/material.model";
import Autocomplete from "@/app/component/autocomplete/Autocomplete.vue";
import {useStore} from "vuex";
import {closeCircleOutline} from "ionicons/icons";

export default defineComponent({
  name: "ProductCreateItemComponent",
  components: {
    Autocomplete,
    IonItem,
    IonInput,
    IonGrid,
    IonRow,
    IonCol,
    IonIcon,
  },
  props: {
    component: null,
    index: null,
  },
  setup(prop: any, {emit}) {
    const store = useStore();
    const componentProp = ref(prop.component);
    const allMaterials = ref<string[]>(store.getters.allMaterial.map((material: Material) => material.ref));
    const placeholder = ref<string>('Selecciona materia prima...');
    const reference = ref<string>('');
    const indexItem = ref(prop.index);
    const amount = ref<any>(0);

    // const changeFocus = async () => {
    //   (await amount.value?.$el.getInputElement()).focus();
    //   const element = await amount.value?.$el.getInputElement();
    //   setTimeout(() => {
    //     element.select();
    //   })
    // };
    const getMaterial = (ref: string) => store.getters.materialByRef(ref);
    const onSelectMaterial = (materialRef: string) => {
      componentProp.value.ref = materialRef;
      reference.value = materialRef;
      // changeFocus();
    };

    const onNextItem = () => {
      emit('on-next-item', 'test');
    };

    const deleteItem = () => {
      emit('on-delete-item', indexItem)
    };
    return {
      amount,
      onNextItem,
      deleteItem,
      getMaterial,
      reference,
      placeholder,
      onSelectMaterial,
      componentProp,
      allMaterials,
      closeCircleOutline,
    };
  }
})
